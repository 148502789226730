.use-case-post-subtitle {
  margin-bottom: 20px !important;
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif;
  font-size: 32px;
  line-height: 140%;
  color: rgba(0, 0, 0, 0.75);
}

@media only screen and (max-width: 768px) {
  .use-case-post-subtitle {
    font-size: 28px;
    line-height: 140%;
  }
}
