.use-case-post-image {
  display: inline-flex;
  flex-direction: column;
}
.use-case-post-image img {
  width: 100%;
  max-width: 650px;
}
.use-case-post-image .image-caption {
  margin: 16px 0 0 0;
  font-size: 18px;
  line-height: 120%;
  color: rgba(0, 0, 0, 0.5);
  width: 80%;
}

@media only screen and (max-width: 768px) {
  .use-case-post-image .image-caption {
    width: 100%;
    font-size: 20px;
    line-height: 120%;
  }
}
