.use-case-post-slideshow {
  font-size: 24px;
  line-height: 150%;
  position: relative;
}
.use-case-post-slideshow a {
  color: rgb(51, 119, 243);
}
.use-case-post-slideshow a:hover {
  text-decoration: underline;
}

.use-case-post-slideshow .slider-wrapper {
  padding: 80px 0 120px 0;
  background: #eff2f3;
}
.use-case-post-slideshow .slider-wrapper .image-slider-wrapper {
  margin-left: 35px;
  margin-right: 35px;
}
.use-case-post-slideshow .slider-wrapper .image-slider-title {
  font-size: 36px;
  line-height: 100%;
}
.use-case-post-slideshow .slider-wrapper .image-slider-description {
  margin-top: 46px;
  margin-bottom: 20px;
}
.use-case-post-slideshow .slider-wrapper .image-slider-description-text {
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  font-size: 18px;
  line-height: 140%;
}
.use-case-post-slideshow
  .slider-wrapper
  .image-slider-description-text
  .text-code {
  font-family: "Consolas", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 140%;
  padding: 2px 4px;
  background: #ffffff;
  border: 0.25px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
.use-case-post-slideshow .slider-wrapper .image-slider-image-wrapper {
  overflow: hidden;
  position: relative;
}
.use-case-post-slideshow
  .slider-wrapper
  .image-slider-image-wrapper.is-not-active {
  border: none;
}
.use-case-post-slideshow .slider-wrapper .image-slider-image {
  border-radius: 16px;
  border: 3px solid #90cf7e;
}
.use-case-post-slideshow .slider-wrapper .image-slider-overlay {
  border: 1px solid #000;
  border-radius: 16px;
}

@media only screen and (max-width: 768px) {
  .use-case-post-slideshow {
    font-size: 20px;
    line-height: 150%;
  }

  .use-case-post-slideshow .slider-wrapper .image-slider-wrapper {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.use-case-post-slideshow .container {
  height: 500px;
  width: 100% !important;
  /* border: 2px orange solid; */
}

.use-case-post-slideshow .slider {
  display: flex;
  height: 100%;
  width: 166.666666667%;
  flex-shrink: 0;
  transition: all 0.5s;
}

.use-case-post-slideshow .carousel {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  position: relative;
}

.use-case-post-slideshow .slider section {
  flex-basis: 20%;
  /* border: 2px green solid; */
  width: 20%;
  /* flex-shrink: 0; */
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*
.controls{
    top: 10px;
    @apply px-40;
    @apply absolute;
    @apply flex h-full w-full;
    @apply items-center;
    @apply justify-between;
} */

.use-case-post-slideshow .arrownext {
  @apply w-16;
  @apply h-16;
  @apply p-4;
  @apply border;
  @apply border-black;
  @apply rounded-full;
  @apply bg-white;
  @apply flex;
  @apply justify-center;
  @apply items-center;
  @apply absolute;
  top: 33.333333%;
  right: 0;
  cursor: pointer;
  margin-right: 23%;
}

.use-case-post-slideshow .arrowprev {
  @apply w-16;
  @apply h-16;
  @apply p-4;
  @apply border;
  @apply border-black;
  @apply rounded-full;
  @apply bg-white;
  @apply flex;
  @apply justify-center;
  @apply items-center;
  @apply absolute;
  top: 33.333333%;
  left: 0;
  cursor: pointer;
  margin-left: 23%;
}

.use-case-post-slideshow .arrowprev svg {
  @apply w-6;
}

.use-case-post-slideshow .arrownext svg {
  @apply w-6;
}
