ul.pricing-feature-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

ul.pricing-feature-list li {
  display: flex;
  align-items: center;
  line-height: 140%;
  font-size: 18px;
}

ul.pricing-feature-list li a {
  @apply text-sym-blue;
}

ul.pricing-feature-list li:last-child {
  margin-bottom: 0;
}

.pricing-feature-list li:before {
  content: "";
  width: 9px;
  height: 7px;
  margin-right: 16px;
  background-size: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.26017 6.1226L1.12657 3.94586L0.400024 4.68188L3.26017 7.59985L9.40002 1.33587L8.67859 0.599854L3.26017 6.1226Z' fill='%23EC0FFF'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  flex-shrink: 0;
}

.pricing-panel-price-text {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif;
  font-weight: 800;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    92deg,
    #fff 16.22%,
    #99f3e5 72.4%,
    #ec0fff 115.44%
  );
  -webkit-background-clip: text;
  background-clip: text;
}

.pricing-panel-separator {
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, #47a7ff 2.95%, #ec0fff 126.16%);
  display: block;
  margin: 36px 0;
}

.pricing-panel-most-popular-tag {
  width: 160px;
  position: absolute;
  top: -12px;
  left: calc(50% - 80px);
}

div.pricing-hero {
  background-color: white;
}

.pricing-hero {
  width: 100%;
  max-width: 1000px;
  padding-top: 100px;
  padding-bottom: 100px;
}

div.pricing-hero-logos {
  margin-top: 60px;
}

.pricing-hero-logos {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 25px 30px 25px;
}
