.legalPage {
  text-align: left;
  padding-top: 60px;
  padding-bottom: 60px;
}

.legalPage ul {
  @apply list-disc;
  @apply px-8;
}
.legalPage li {
  margin-bottom: 12px;
}

.legalPage h1 {
}

.legalPage h2 {
}

.legalPage h3 {
  margin-bottom: 16px;
}

.legalPage h4 {
  margin-bottom: 8px;
}

.legalPage p {
  font-size: 16px;
  line-height: 140%;
}

.indentedBlock {
  @apply px-8;
}

.subprocessorTable {
  text-align: left;
  width: 100%;
  font-size: 18px;
  line-height: 140%;
}

.subprocessorTable td,
th {
  text-align: left;
  padding-bottom: 1em;
  padding-right: 2em;
}

@media only screen and (max-width: 968px) {
  .subprocessorTable {
    font-size: 14px;
  }
  .subprocessorTable td,
  th {
    padding-right: 1em;
  }
}

@media only screen and (max-width: 768px) {
  .legalPage h1 {
    font-size: 36px;
  }
  .legalPage h2 {
    font-size: 28px;
  }
  .legalPage h3 {
    font-size: 22px;
  }
}
