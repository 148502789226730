.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.slick-slide > div {
  height: 100%;
}
