.animation-container {
  opacity: 0;
  visibility: hidden;
}

.animation-default {
  transition: opacity 1200ms ease-in;
}

.animation-visible {
  padding-top: 0px;
  opacity: 1;
  visibility: visible;
  width: 100%;
}
