@keyframes fadeInTestimonial {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.testimonials-block-animation {
  transition: opacity 1000ms ease-in;
}
.testimonials-block .testimonial-fade {
  animation: fadeInTestimonial 1s;
}

.testimonials-block:before,
.testimonials-block:after {
  content: "";
  display: block;
  width: 100%;
  height: 120px;
  background-image: url(../../../static/img/testimonials-border.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.testimonials-block:before {
  transform: rotate(180deg);
}
@media only screen and (max-width: 768px) {
  .testimonials-block:before,
  .testimonials-block:after {
    height: 60px;
  }
}
