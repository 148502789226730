.workflow-white-sections-animation {
  transition: opacity 1000ms ease-in;
}

.compliance-workflow-container {
  width: 100%;
  margin: 0 auto;
  padding: 0 30px 80px 30px;
  text-align: center;
}

.compliance-workflow-inner {
  width: 100%;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
}

.compliance-workflow-inner h1 {
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 36px;
  line-height: 130%;
  text-transform: uppercase;
  margin-bottom: 40px;
}

.compliance-workflow-inner p {
  width: 100%;
  max-width: 920px;
  margin: 0 auto;
  font-size: 20px;
  line-height: 140%;
}

.compliance-workflow-inner .workflow-diagram {
  width: 100%;
  max-width: 1200px;
  margin: 50px auto 0 auto;
}

@media only screen and (max-width: 1023px) {
  .compliance-workflow-container {
    max-width: 940px;
    padding-top: 50px;
    padding-bottom: 32px;
  }
}

@media only screen and (max-width: 768px) {
  .compliance-workflow-inner {
    margin: 0;
    flex-direction: column;
  }

  .compliance-workflow-inner h1 {
    margin-bottom: 60px;
  }

  .compliance-workflow-inner p {
    font-size: 18px;
    line-height: 140%;
  }
}
