.sales-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  animation: fadeIn ease 1s;
  font-size: 24px;
  text-align: center;
}
.sales-form.is-boxed {
  width: 100%;
  max-width: 1200px;
  margin: 60px auto;
}
@media only screen and (max-width: 768px) {
  .sales-form {
    font-size: 18px;
  }
}
.sales-form .sales-form-wrapper {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 30px 20px 60px 20px;
  display: flex;
  flex-direction: column;
}
.sales-form h2 {
  margin: 0;
  font-size: 48px;
  line-height: 58px;
  text-transform: uppercase;
  font-weight: bold;
}
@media only screen and (max-width: 768px) {
  .sales-form h2 {
    font-size: 32px;
  }
}
.sales-form .email-form {
  display: flex;
  flex-direction: column;
  margin: 40px 0 0 0;
}
.sales-form .email-form .input-split {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sales-form .email-form .input-split > * {
  width: 100%;
  max-width: 420px;
  padding: 0 20px;
}
@media only screen and (max-width: 768px) {
  .sales-form .email-form .input-split {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .sales-form .email-form .input-split > * {
    padding: 0;
    max-width: 500px;
    margin: 0 auto 30px auto;
  }
  .sales-form .email-form .input-split > *:last-child {
    margin-bottom: 0;
  }
}
.sales-form .error-message {
  flex: 1;
  margin: 30px 0 -20px 0;
  font-weight: 600;
  color: red;
}
.sales-form .submitted-message {
  font-size: 24px;
}
.sales-form .submitted-message p {
  margin: 60px 0 20px 0;
}
