.use-case-post-text {
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  font-size: 20px;
  line-height: 140%;
}
.use-case-post-text a {
  color: rgb(51, 119, 243);
}
.use-case-post-text a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .use-case-post-text {
    font-size: 18px;
    line-height: 140%;
  }
}
