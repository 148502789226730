.home-hero-section-animation {
  transition: opacity 1000ms ease-in;
}

.hero-section-wrapper {
}

.hero-section-wrapper .hero-text {
  width: 100%;
  max-width: 480px;
}

.hero-section-wrapper .hero-section-title {
  width: 100%;
}
.hero-section-wrapper .hero-section-title .text-sym-yellow::before {
  content: "\a";
  white-space: pre;
}
.hero-section-wrapper .hero-section-title .typing-container {
  display: inline-block;
}
@media only screen and (max-width: 1200px) {
  .hero-section-wrapper .hero-section-title {
    width: 100%;
    max-width: 700px;
  }
  .hero-section-wrapper .hero-section-title .text-sym-yellow::before {
    content: "";
  }
}
@media only screen and (max-width: 968px) {
  .hero-section-wrapper .hero-section-title {
    font-size: 42px;
  }
}
@media only screen and (max-width: 768px) {
  .hero-section-wrapper .hero-section-title {
    font-size: 32px;
  }
}
@media only screen and (max-width: 568px) {
  .hero-section-wrapper .hero-section-title {
    font-size: 26px;
  }
}

.hero-section-wrapper .hero-top-wrapper {
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  gap: 24px;
}

.hero-section-wrapper .hero-text-section {
  width: 100%;
  max-width: 800px;
}

.try-sym-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.home-hero-img-wrapper {
  flex: 1;
  position: relative;
  filter: drop-shadow(0 0 30px rgba(255, 255, 255, 0.2));
}
.home-hero-img-wrapper video {
  width: 100%;
}
.home-hero-img-wrapper .home-hero-image {
  display: inline-block;
}

.hero-section-wrapper .home-hero-logos {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 25px 60px 25px;
}

.home-cta-video-wrapper {
  height: 100%;
  max-height: 520px;
  position: relative;
  display: flex;
  justify-content: center;
}
.home-cta-video-wrapper .home-hero-video-toggle {
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 1420px) {
  .home-hero-img {
    max-width: 820px;
  }
}

@media only screen and (max-width: 1200px) {
  .home-hero-img {
    display: none;
  }
  .hero-section-wrapper .hero-text-section {
    max-width: none;
    text-align: center;
  }
  .hero-section-wrapper .hero-text-section .try-sym-wrapper {
    margin-left: auto;
    margin-right: auto;
  }
  .hero-section-wrapper .hero-section-title {
    margin-left: auto;
    margin-right: auto;
  }
  .hero-section-wrapper .hero-text {
    max-width: none;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 1024px) {
  .home-hero-img {
    max-width: 650px;
  }
}

@media only screen and (max-width: 768px) {
  .hero-section-wrapper .home-hero-logos {
    padding: 0 20px 40px 20px;
  }
  .try-sym-wrapper {
    justify-content: center;
    gap: 16px;
  }
}
