/*.home-hero-video-animation {*/
/*  transition: opacity 1000ms ease-in;*/
/*}*/

.home-hero-video-container {
  display: inline-flex;
  justify-content: center;
}

.home-hero-video-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
}

.home-hero-video-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99997;
  position: absolute;
  top: 0;
  left: 0;
}

.home-hero-video-inner {
  width: 100%;
  max-width: 1200px;
  height: 100%;
  max-height: 800px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  z-index: 99998;
}

.home-video-close {
  width: 36px;
  height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 8px;
  right: 8px;
  cursor: pointer;
}
.home-video-close svg {
  width: 16px;
  height: 16px;
  display: inline-block;
}

.home-hero-video-toggle {
  width: 100%;
  max-width: 936px;
  height: 100%;
  max-height: 525px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  aspect-ratio: 1.8 / 1;
  overflow: hidden;
}

.home-hero-video-toggle .play-button {
  width: 80px;
  height: 80px;
  border-radius: 60px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.2));
  cursor: pointer;
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
}
.home-hero-video-toggle .play-button svg {
  position: relative;
  left: 6px;
}

.home-hero-video-toggle .demo-video-preview {
  width: 100%;
  max-width: 936px;
  height: 100%;
  max-height: 525px;
  display: block;
}
.home-hero-video-toggle .demo-video-preview img {
  width: 100%;
  height: 100%;
  display: block;
}

.home-hero-video-toggle .video-preview-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
}
