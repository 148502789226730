.integrations-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 100px;
  animation: fadeIn ease 1s;
  text-align: center;
}

.integrations-list-wrapper {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 48px;
  grid-column-gap: 48px;
}
.integrations-list-wrapper article {
  width: 220px;
  height: 220px;
  margin: 0 auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 4.31433px 4.31433px rgba(203, 210, 214, 0.7));
  background: #f3f4f5;
  border-radius: 5px;
  transition: transform 200ms ease-in;
}
.integrations-list-wrapper article:hover {
  transform: scale(1.03);
}
.integrations-list-wrapper article img {
  max-width: 100px;
  display: block;
}
