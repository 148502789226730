body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  background: #041226;
  color: #fff;
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif;
  font-size: 16px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/*@font-face {*/
/*  font-family: "GintoNord-Bold";*/
/*  src: url("./static/fonts/GintoNord-Bold.otf") format("opentype");*/
/*  font-display: swap;*/
/*}*/

/*@font-face {*/
/*  font-family: "GintoNord-Medium";*/
/*  src: url("./static/fonts/GintoNord-Medium.otf") format("opentype");*/
/*  font-display: swap;*/
/*}*/

/*@font-face {*/
/*  font-family: "GintoNord-Regular";*/
/*  src: url("./static/fonts/GintoNord-Regular.otf") format("opentype");*/
/*  font-display: swap;*/
/*}*/

/*@font-face {*/
/*  font-family: "GintoNormal-Bold";*/
/*  src: url("./static/fonts/GintoNormal-Bold.otf") format("opentype");*/
/*  font-display: swap;*/
/*}*/

/*@font-face {*/
/*  font-family: "GintoNormal-Medium";*/
/*  src: url("./static/fonts/GintoNormal-Medium.otf") format("opentype");*/
/*  font-display: swap;*/
/*}*/

/*@font-face {*/
/*  font-family: "GintoNormal-MediumItalic";*/
/*  src: url("./static/fonts/GintoNormal-MediumItalic.otf") format("opentype");*/
/*  font-display: swap;*/
/*}*/

/*@font-face {*/
/*  font-family: "GintoNormal-Regular";*/
/*  src: url("./static/fonts/GintoNormal-Regular.otf") format("opentype");*/
/*  font-display: swap;*/
/*}*/

/*@font-face {*/
/*  font-family: "GintoNormal-RegularItalic";*/
/*  src: url("./static/fonts/GintoNormal-RegularItalic.otf") format("opentype");*/
/*  font-display: swap;*/
/*}*/

@font-face {
  font-family: "Consolas";
  src: url("./static/fonts/consola.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "DM Sans";
  src: url("./static/fonts/DMSans-Regular.ttf") format("opentype");
  font-display: swap;
  font-weight: normal;
}

@font-face {
  font-family: "DM Sans";
  src: url("./static/fonts/DMSans-Bold.ttf") format("opentype");
  font-display: swap;
  font-weight: bold;
}

/* Screenreaders only text for accessibility */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.page-content {
  /* Update offset for height of top nav in .topNavWrapper (TopNav.module.css) if updating this */
  padding-top: 96px;
}
.page-content.page-content-has-top-banner {
  padding-top: 142px; /* Includes 46px top banner height */
}

@media only screen and (max-width: 1280px) {
  .page-content {
    /* Update offset for height of top nav in .topNavWrapper (TopNav.module.css) if updating this */
    padding-top: 64px;
  }
  .page-content.page-content-has-top-banner {
    padding-top: 110px; /* Includes 46px top banner height */
  }
}

.cta-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  text-align: center;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  text-transform: uppercase;
  color: #061c34;
  background: #ec0fff;
  border: 1px solid #ec0fff;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 2px;
}
.cta-button:disabled {
  background: rgba(0, 0, 0, 0.5);
  color: #666;
  border: 1px solid #111;
  cursor: default;
}

.cta-button.secondary {
  color: #ec0fff;
  background: transparent;
  border-color: #ec0fff;
}
.cta-button.tertiary {
  color: #fff;
  background: transparent;
  border-color: transparent;
}
.cta-button.tertiary:after {
  content: "";
  width: 8px;
  height: 12px;
  margin-left: 10px;
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.935547 10.6882L5.1967 6.41769L0.935546 2.14722L2.24739 0.835373L7.82969 6.41769L2.24739 12L0.935547 10.6882Z' fill='%23EC0FFF'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  display: inline-block;
}

/* Custom classes for highlighting for highlight.js */
.hljs {
  font-weight: bold;
  font-size: 15px;
}
.hjs-mark-custom {
  background: inherit;
  color: inherit;
}
.hjs-mark-custom span {
  background: inherit;
  color: inherit;
}
.hjs-mark-custom.hjs-custom-white {
  color: #ffffff;
}
.hjs-mark-custom.hjs-custom-green {
  color: #90cf7e;
}
.hjs-mark-custom.hjs-custom-blue {
  color: #47a7ff;
}
.hjs-mark-custom.hjs-custom-light-blue {
  color: #99f3e5;
}
.hjs-mark-custom.hjs-custom-yellow {
  color: #ffe86d;
}
.hjs-mark-custom.hjs-custom-purple {
  color: #ec0fff;
}

.font-courier {
  font-family: "Courier", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif;
}

p a {
  @apply text-sym-purple;
}

h1,
h2,
h3,
h4,
h5 {
  display: inline-block;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  margin-block-start: 0;
  margin-block-end: 0;
}

h1 {
  font-size: 46px;
  font-weight: 800;
  text-transform: uppercase;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    92deg,
    #fff 16.22%,
    #99f3e5 72.4%,
    #ec0fff 115.44%
  );
  -webkit-background-clip: text;
  background-clip: text;
}

h2 {
  font-size: 36px;
  font-weight: 800;
  text-transform: uppercase;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    92deg,
    #fff 16.22%,
    #99f3e5 72.4%,
    #ec0fff 115.44%
  );
  -webkit-background-clip: text;
  background-clip: text;
}

h3 {
  font-size: 22px;
  line-height: 140%;
  font-weight: 800;
}

h4 {
  font-size: 20px;
  line-height: 140%;
  font-weight: 400;
}

h5 {
  font-size: 18px;
  line-height: 140%;
  font-weight: 400;
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 968px) {
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 22px;
  }
  h4 {
    font-size: 18px;
  }
  h5 {
    font-size: 16px;
  }
}

.font-mono {
  font-family: "IBM Plex Mono", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, monospace;
}

.bg-gradient-fade {
  background: linear-gradient(
    93.59deg,
    #0b142b 26.27%,
    rgba(11, 20, 43, 0) 115.1%
  );
}

.input-wrapper {
  position: relative;
}
.input-wrapper input.text-input {
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  font-size: 100%;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  -webkit-backface-visibility: hidden;
  border: 1px solid #47a7ff;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.radio-button .radio-check {
  width: 24px;
  height: 24px;
  margin: 0 12px 0 0;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: 1px solid #47a7ff;
  cursor: pointer;
  background: transparent;
  position: relative;
}
.radio-button input[type="radio"]:checked + .radio-check:after {
  display: none;
  width: 10px;
  height: 10px;
  background: #ec0fff;
  content: "";
  border-radius: 2px;
}
.radio-button input[type="radio"]:checked + .radio-check:after {
  display: inline-flex;
}
.radio-button .radio-check:hover {
  background: rgba(0, 0, 0, 0.05);
}

.main-content-bg {
  position: relative;
}
.main-content-bg > * {
  position: relative;
  z-index: 1;
}
.main-content-bg-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
}
.main-content-bg-element-1,
.main-content-bg-element-2,
.main-content-bg-element-3,
.main-content-bg-element-4 {
  position: absolute;
  display: block;
}
.main-content-bg-element-1 {
  width: 480px;
  height: 460px;
  opacity: 0.3;
  background: conic-gradient(
    from 45deg at 50% 50%,
    #a7d4ff 0deg,
    #667581 181.87499284744263deg,
    #4ff 360deg
  );
  filter: blur(150px);
  top: 240px;
  left: -200px;
}
.main-content-bg-element-2 {
  width: 430px;
  height: 540px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='430' height='542' viewBox='0 0 430 542' fill='none'%3E%3Cg opacity='0.24' filter='url(%23filter0_f_294_17)'%3E%3Cpath d='M299.475 260.861C302.675 212.861 235.475 134.194 201.475 100.861C23.4753 88.8611 133.475 282.861 139.475 344.861C145.475 406.861 345.475 458.861 328.475 436.861C311.475 414.861 295.475 320.861 299.475 260.861Z' fill='%23EC0FFF'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f_294_17' x='0.39209' y='0.328125' width='429.108' height='541.672' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='50' result='effect1_foregroundBlur_294_17'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
  background-size: cover;
  top: 800px;
  left: -250px;
}
.main-content-bg-element-3 {
  width: 480px;
  height: 460px;
  opacity: 0.3;
  background: conic-gradient(
    from 45deg at 50% 50%,
    #a7d4ff 0deg,
    #667581 181.87499284744263deg,
    #4ff 360deg
  );
  filter: blur(150px);
  top: 1200px;
  right: -200px;
}
.main-content-bg-element-4 {
  width: 650px;
  height: 540px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='648' height='542' viewBox='0 0 648 542' fill='none'%3E%3Cg opacity='0.24' filter='url(%23filter0_f_294_18)'%3E%3Cpath d='M489.289 260.687C495.546 212.641 364.142 133.899 297.659 100.533C-50.4038 88.5219 164.691 282.708 176.423 344.768C188.156 406.827 579.238 458.877 545.996 436.856C512.754 414.835 481.467 320.745 489.289 260.687Z' fill='%23EC0FFF'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f_294_18' x='0' y='0' width='648' height='542' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='50' result='effect1_foregroundBlur_294_18'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
  background-size: cover;
  top: 1800px;
  right: -450px;
}

/*Hero Backgrounds*/
.hero-bg-wrapper {
  position: relative;
  overflow: hidden;
}
.hero-bg-wrapper > * {
  z-index: 1;
  position: relative;
}
.hero-bg-wrapper .hero-bg {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.hero-bg-wrapper .hero-bg:before,
.hero-bg-wrapper .hero-bg:after {
  content: "";
  width: 100%;
  height: 400px;
  position: absolute;
  left: 0;
}
.hero-bg-wrapper .hero-bg:before {
  background: linear-gradient(180deg, #041125 0%, rgba(4, 17, 37, 0) 100%);
  top: 0;
}
.hero-bg-wrapper .hero-bg:after {
  background: linear-gradient(0deg, #041125 0%, rgba(4, 17, 37, 0) 100%);
  bottom: 0;
}

/*Home Hero Background*/
.hero-bg.home-hero-bg {
  background-image: url(./static/img/bg/bg-home-hero.jpg);
}

/*Pricing Hero Background*/
.hero-bg.pricing-hero-bg {
  background-image: url(./static/img/bg/bg-pricing-hero.jpg);
}

/*Customers Hero Background*/
.hero-bg.customers-hero-bg {
  background-image: url(./static/img/bg/bg-customers-hero.svg);
}

/*Security Hero Background*/
.hero-bg.security-hero-bg {
  background-image: url(./static/img/bg/bg-security-hero.svg);
}

/*Jit Hero Background*/
.hero-bg.jit-hero-bg {
  background-image: url(./static/img/bg/bg-jit-hero.svg);
}

/*Sales Hero Background*/
.hero-bg.sales-hero-bg {
  background-image: url(./static/img/bg/bg-sales-hero.svg);
}

/*Careers Hero Background*/
.hero-bg.careers-hero-bg {
  background-image: url(./static/img/bg/bg-careers-hero.svg);
}

/*About Hero Background*/
.hero-bg.about-hero-bg {
  background-image: url(./static/img/bg/bg-about-hero.svg);
}

/*Integration Hero Background*/
.hero-bg.integration-hero-bg {
  background-image: url(./static/img/bg/bg-integration-hero.svg);
}

/*Product Hero Background*/
.hero-bg.product-hero-bg {
  background-image: url(./static/img/bg/bg-product-hero.svg);
}

/*Login Background*/
.hero-bg.login-bg {
  background-image: url(./static/img/bg/bg-login.svg);
}

.sym-hbspt-form * {
  background: red !important;
}

.hbspt-form * {
  background: blue !important;
}

.hbspt-form {
  background: green !important;
}
.sym-test {
  background: orange !important;
}
