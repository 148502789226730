.home-testimonial-section-animation {
  transition: opacity 1000ms ease-in;
}

.leading-none {
  margin-bottom: 30px;
}
.leading-none img {
  width: 40px;
  height: 30px;
  display: block;
}

.testimonial-step-text {
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 140%;
  margin-top: -36px;
}

.step-name {
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 140%;
}

.step-title {
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
}

.testimonial-step-text {
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 140%;
}

.home-testimonial-mobile {
  background: #312f2f;
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .testimonial-step-text {
    font-size: 16px;
  }
}

.home-testimonials {
  display: flex;
  flex-direction: row;
  margin-right: -28px;
  margin-top: 60px;
}
.home-testimonials .testimonial {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 28px;
  padding: 40px 30px 55px 30px;
  background: #312f2f;
  border-radius: 25px 25px 0px 0px;
  color: #fff;
}
.home-testimonials .testimonial-logo {
  height: 50px;
  margin-bottom: 75px;
  display: inline-flex;
  align-items: center;
}
.home-testimonials .testimonial-logo img {
  max-height: 50px;
  display: inline-block;
}
.home-testimonials .testimonial-text {
  margin-bottom: 36px;
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 140%;
}
.home-testimonials .testimonial-author {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-top: auto;
}
.home-testimonials .testimonial-author-photo {
  width: 64px;
  height: 64px;
  display: inline-block;
  background-size: 100%;
  margin-right: 12px;
  border-radius: 8px;
  border: 1px solid #000;
}
.home-testimonials .testimonial-author-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
}
.home-testimonials .testimonial-author-text h5 {
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: bold;
}
.home-testimonials .testimonial-author-text p {
  font-size: 18px;
  line-height: 120%;
}

@media only screen and (max-width: 1024px) {
  .home-testimonials .testimonial {
    padding: 40px 16px 55px 16px;
  }
  .home-testimonials .testimonial-logo {
    height: 40px;
  }
  .home-testimonials .testimonial-logo img {
    max-height: 40px;
  }
  .home-testimonials .testimonial-text {
    font-size: 20px;
  }
  .home-testimonials .testimonial-author-text h5 {
    font-size: 20px;
  }
  .home-testimonials .testimonial-author-text p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 968px) {
  .home-testimonials .testimonial-author {
    flex-direction: column;
    align-items: flex-start;
  }
  .home-testimonials .testimonial-author-photo {
    margin-right: 0;
    margin-bottom: 16px;
  }
  .home-testimonials .testimonial-author-text h5 {
    margin-bottom: 4px;
  }
}

@media only screen and (max-width: 768px) {
  .home-testimonials.home-testimonials-desktop {
    display: none;
  }
  .leading-none {
    margin-bottom: 54px;
  }
  .leading-none img {
    width: 16px;
    height: 11px;
  }
  .testimonial-mobile-logo {
    margin-bottom: 25px;
  }
}
