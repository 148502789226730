.navBlock {
  @apply mr-4;
}

.navBlock li {
  @apply flex;
  @apply flex-col;
  @apply justify-start;
  @apply text-lg;
  @apply font-normal;
  @apply mt-4;
  @apply cursor-pointer;
}

.navBlock li a {
  display: inline-block;
  text-align: start;
  @apply text-lg;
}

.navBlock h5 {
  @apply text-base;
  @apply uppercase;
  @apply font-semibold;
}

.navBlock li a:hover {
  font-weight: 500;
}

.navBlock li a::before {
  display: block;
  content: attr(title);
  font-weight: bold;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

@media only screen and (max-width: 1090px) {
  .legalBlock {
    @apply hidden;
  }
}

@media only screen and (max-width: 768px) {
  .navBlock li a {
    display: inline-block;
    text-align: start;
    @apply text-sm;
  }

  .navBlock h5 {
    @apply text-sm;
  }
}
