.home-how-it-works-section-animation {
  transition: opacity 1000ms ease-in;
}

.how-it-works-wrapper {
  padding: 1px;
  background: linear-gradient(180deg, #47a7ff 3.1%, #ec0fff 157.56%);
}
.how-it-works-top-bar {
  height: 56px;
  background: linear-gradient(
    90.34deg,
    #761db6 22.58%,
    rgba(71, 167, 255, 0.49) 121.98%
  );
}

.how-it-works-tab {
  line-height: 100%;
  border: 1px solid #060b18;
  border-bottom: none;
}
.how-it-works-tab.active {
  border-color: #ec0fff;
  box-shadow: 0 1px 0 #060b18;
}
@media only screen and (max-width: 1024px) {
  .how-it-works-tab {
    line-height: 120%;
    border-bottom: 1px solid #060b18;
  }
}

.how-it-works-bg-img {
  min-height: 300px;
  background-size: 260px 300px;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 1024px) {
  .how-it-works-bg-img {
    background-position: center;
  }
}
