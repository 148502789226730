.slider-handle {
  --rcs-handle-box: 3.4rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5px;
  height: 100%;
  box-shadow: none;
  cursor: pointer;
  @apply bg-sym-green;
}

.slider-handle-arrow-box {
  position: absolute;
  width: var(--rcs-handle-box);
  height: var(--rcs-handle-box);
  border-radius: 100%;
  overflow: hidden;
  background-color: white;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
}

.slider-handle-arrow-box::before,
.slider-handle-arrow-box::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  width: inherit;
  height: inherit;
}

.slider-handle-arrows {
  position: absolute;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-auto-flow: column;
  width: var(--rcs-handle-box);
  height: var(--rcs-handle-box);
  gap: 0.65rem;
  border-radius: 100%;
  overflow: hidden;
}

.slider-handle-arrows::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  top: 50%;
  left: 0;
  border-top: 0.5rem solid transparent;
  border-bottom: 0.5rem solid transparent;
  border-right: 0.65rem solid #0039f0;
}

.slider-handle-arrows::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  top: 50%;
  left: 0;
  border-top: 0.5rem solid transparent;
  border-bottom: 0.5rem solid transparent;
  border-left: 0.65rem solid #0039f0;
}

.slider-container {
  width: 100%;
  animation: fadeIn ease 1s;
  /* max-width: 666px; */
}

.chevron::before {
  border-style: solid;
  border-width: 0.14em 0.14em 0 0;
  border-color: #0039f0;
  content: "";
  display: inline-block;
  height: 0.45em;
  left: 0.15em;
  position: relative;
  top: 0.25em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 0.45em;
}

.chevron.right:before {
  left: 3px;
  transform: rotate(45deg);
}

.chevron.left:before {
  left: -3px;
  transform: rotate(-135deg);
}

.sliderTitle {
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 140%;
  text-transform: uppercase;
}

.sliderDesc {
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
}

.sliderOverlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #90cf7e;
  z-index: 1;
  border-radius: 25px 25px 0px 0px;
}

.sliderImage {
  width: 100%;
  max-width: 930px;
  max-height: 690px;
  margin: 0 auto;
  display: block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.compareSlider {
  border-radius: 25px 25px 0px 0px;
}

@media only screen and (max-width: 855px) {
  .slider-handle {
    --rcs-handle-box: 2.4rem;
  }

  .chevron::before {
    height: 0.3em;
    width: 0.3em;
    top: 0.35em;
  }

  .chevron.right:before {
    left: 1px;
  }

  .chevron.left:before {
    left: -1px;
  }

  .slider-container {
    width: 100%;
    padding-bottom: 50px;
  }

  .compareSlider {
    border-radius: 10px 10px 0px 0px;
  }
}

.imageSliderImageContainer {
  position: relative;
}
.imageSliderImageOverlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #90cf7e;
  z-index: 1;
  border-radius: 16px;
  border: 1px solid #000;
}
.hideImageSliderOverlay {
  display: none;
}

.fadein {
  -webkit-animation: fadein 0.7s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.7s;
  /* Firefox < 16 */
  -ms-animation: fadein 0.7s;
  /* Internet Explorer */
  -o-animation: fadein 0.7s;
  /* Opera < 12.1 */
  animation: fadein 0.7s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
