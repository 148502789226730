.home-product-features-section-animation {
  transition: opacity 1000ms ease-in;
}

.product-features-items {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.product-features-item {
  margin-bottom: 90px;
}
.product-features-item:last-child {
  margin-bottom: 0;
}
.product-features-item-inner {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
.product-features-item:nth-child(odd) .product-features-item-inner {
  flex-direction: row;
}

.product-features-text-content {
  width: 100%;
  max-width: 530px;
  display: flex;
  flex-direction: column;
  margin-right: 24px;
}
.product-features-item:nth-child(odd) .product-features-text-content {
  margin-left: 24px;
  margin-right: 0;
}

.product-features-title {
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 140%;
  margin-bottom: 24px;
}

.product-features-desc {
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
}

.product-features-image-container {
  width: 100%;
  max-width: 530px;
  display: inline-flex;
  /*box-shadow: 53px 65px 100px rgba(203, 210, 214, 0.6);*/
}
.product-features-image {
}

@media only screen and (max-width: 768px) {
  .product-features-item {
    padding-bottom: 50px;
    margin-bottom: 50px;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.15);
    text-align: center;
  }
  .product-features-item:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  .product-features-item-inner,
  .product-features-item:nth-child(odd) .product-features-item-inner {
    flex-direction: column;
    align-items: center;
  }
  .product-features-item:nth-child(odd) .product-features-text-content {
    margin-left: 0;
  }
  .product-features-image-container {
    max-width: none;
    justify-content: center;
  }
  .product-features-text-content {
    max-width: none;
    margin-left: 0;
    margin-right: 0;
  }
  .product-features-title {
    margin: 8px 0 12px 0;
    font-size: 24px;
  }
  .product-features-desc {
    font-size: 16px;
  }
  .product-features-image {
    max-width: 360px;
  }
}
