.announcementBanner {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 46px;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  font-size: 1.1em;
  font-weight: bold;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

@media only screen and (max-width: 768px) {
  .announcementBanner {
    font-size: 0.9em;
  }
}

.announcementBanner a {
  text-decoration: underline;
}

.announcementBannerText:after {
  content: "";
  width: 8px;
  height: 12px;
  margin-left: 10px;
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.935547 10.6882L5.1967 6.41769L0.935546 2.14722L2.24739 0.835373L7.82969 6.41769L2.24739 12L0.935547 10.6882Z' fill='%23EC0FFF'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  display: inline-block;
}

.announcementBannerText span {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(92deg, #99f3e5 72.4%, #ec0fff 115.44%);
  -webkit-background-clip: text;
  background-clip: text;
}

.topNav {
  background: #041226;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.topNav .navCompanyWrapper {
  color: white;
}

.topNav .navCompanyWrapper img {
  stroke: white;
  fill: white;
}

.navList {
  gap: 40px;
}

.navitemli {
  display: inline-block;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.navDropdownWrapper {
  display: flex;
  position: relative;
  overflow: visible;
}
.navDropdownParentLink {
  cursor: pointer;
}
.mobileNavDropdownIconOpen {
  transform: rotate(180deg);
}

.navDropdownWrapper img {
  width: 11px;
  flex-shrink: 0;
  margin-left: 8px;
  transition: 350ms ease;
}

.dropdownOpen img {
  transform: rotate(180deg);
}

.desktopNavDropdownList {
  text-transform: none;
}

.mobileNavList {
}

.mobileNavList li {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.mobileNavList .mobileNavDropdownList {
  display: flex;
  flex-direction: column;
  text-transform: none;
  font-weight: 500;
  padding-bottom: 8px;
}

.mobileNavDropdownIcon svg {
  filter: invert(1);
  width: 16px;
}
.mobileNavDropdownIcon svg path {
  stroke: #000;
}

.navDropDown {
  width: 150px;
  max-height: 75vh;
  overflow: auto;
  box-sizing: border-box;
  border-radius: 5px;
  position: absolute;
  top: 37px;
  left: 0px;
  padding: 16px;
}
.navDropDownProduct {
  width: 600px;
}
.navDropDownProduct > div {
  flex: 1;
}
.navDropDownProduct a {
  width: auto !important;
  display: inline !important;
}

.navDropDownItem {
  font-size: 17px;
  line-height: 22px;
  text-align: left;
  position: relative;
  color: white;
}
.navDropDownItem a {
  width: 100%;
  display: block;
}

.useCasesDropdownTrigger {
  padding: 18px 24px;
  margin: -18px -24px !important;
  display: flex;
  align-items: center;
}
.useCasesDropdownTrigger a {
  flex: 1;
}
.useCasesDropdownArrow {
  transform: rotate(-90deg);
}
.useCasesDropdown {
  width: 250px;
  left: 95%;
  top: 6px;
  font-weight: normal;
  background: rgba(255, 255, 255, 0.95);
  text-shadow: none;
}

.navCompanyWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.navDropDownItem {
  margin-bottom: 12px;
  font-weight: normal;
}

.navDropDownItem:last-child {
  margin-bottom: 0;
}

.navitem {
  display: inline-block;
  text-align: center;
  font-size: 18px;
}

.navitem::before {
  display: block;
  content: attr(title);
  font-weight: bold;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.topNavWrapper {
  /* Update offset for height of top nav in .page-content (index.css) if updating this */
  height: 96px;
  display: flex;
  align-items: center;
  z-index: 1;
}

@media only screen and (max-width: 1280px) {
  .topNavWrapper {
    /* Update offset for height of top nav in .page-content (index.css) if updating this */
    height: 64px;
  }
}

.topNavHasTopBanner .topNavWrapper {
  height: 142px; /* Top wrapper + 46px banner height */
  padding-top: 46px; /* Padding top = 46px banner height */
}

@media only screen and (max-width: 1280px) {
  .topNavHasTopBanner .topNavWrapper {
    height: 110px; /* Top wrapper + 46px banner height */
    padding-top: 46px; /* Padding top = 46px banner height */
  }
}

.topNavDropDownSubtitle {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 100%;
  font-weight: bold;
}

.topNavIntegrations {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px dashed rgba(0, 0, 0, 0.4);
}
.navDropDownItemIntegration {
  font-size: 16px;
  margin-bottom: 4px;
  text-transform: none;
}
.navDropDownItemIntegration a {
  display: inline-flex;
  align-items: center;
}
.navDropDownItemIntegration img {
  display: inline-block;
  margin: 0 6px 0 0;
  width: 16px;
  opacity: 0.8;
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .navitem {
    display: inline-block;
    text-align: center;
    font-size: 15px;
  }

  .navCompanyWrapper {
    font-size: 15px;
  }
}
