.code-block-window-wrapper {
  display: flex;
  padding: 40px 24px 24px 24px;
  background: #000; /* Should match highlight.js active theme */
  border-radius: 9px;
  position: relative;
  overflow-x: auto;
}
.code-block-window-wrapper .hljs {
  padding: 0;
}
.code-block-window-wrapper .window-top-buttons {
  display: inline-flex;
  padding-left: 14px;
  position: absolute;
  top: 15px;
  left: 16px;
}
.code-block-window-wrapper .window-inner-buttons {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #fac348;
  position: relative;
}
.code-block-window-wrapper .window-inner-buttons:before {
  content: "";
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 10px;
  background: #f46a64;
  position: relative;
  left: -15px;
}
.code-block-window-wrapper .window-inner-buttons:after {
  content: "";
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 10px;
  background: #5dcd52;
  position: relative;
  left: 5px;
}
